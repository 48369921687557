.container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
}

.leftContainer {
    display: flex;
    align-items: center;
}

.rightContainer {
    display: flex;
    align-items: center;
}

@media screen and (max-width: 992px) {
    .container {
        display: block;
    }

    .leftContainer {
        margin-bottom: 10px;
    }
}