.menuLinkList {
  padding: 23px 25px 22px 22px;
}

.menuLinkItem {
  display: flex;
  padding: 18px 25px 18px 28px;
  align-items: center;
  margin-bottom: 0;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

.menuLinkItem:hover,
.menuLinkItemActive {
  background: #efeff0;
}

.menuLinkItem .image {
  width: 24px;
  height: 24px;
  margin-right: 20px;
}

.menuLinkItem .image svg {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: contain;
}

.menuLinkName {
  font-size: 14px;
  color: #838383;
  font-weight: 500;
}

.menuLinkItem:hover .menuLinkName,
.menuLinkItemActive .menuLinkName {
  color: #313131;
  font-weight: 600;
}

.menuLinkItem:hover svg,
.menuLinkItemActive svg {
  fill: #cf2c47;
}

@media screen and (max-width: 992px) {
  .menuLinkItem {
    padding: 19px 20px 18px 20px;
  }

  .menuLinkItem .image {
    margin-right: 32px;
  }
}

.badge {
  margin-left: 10px;
  background-color: #cf2c47;
  color: white;
  border-radius: 12px;
  font-size: 12px;
  display: flex;
  height: 20px;
  min-width: 50px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  padding-bottom: 1px;
}
