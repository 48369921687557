.selectContainer {
  position: relative;
  font-family: Arial, sans-serif;
}

.selectBox {
  background: white;
  cursor: pointer;
}

.optionsList {
  position: absolute;
  width: 120px;
  border: 1px solid #ccc;
  border-top: none;
  background: white;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 150px;
  overflow-y: auto;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  left: -30px;
  top: 20px;
}

.optionItem {
  padding: 10px;
  cursor: pointer;
  transition: background 0.2s;
  font-weight: 400;
  font-size: 14px;
}

.optionItem:hover {
  background: #f0f0f0;
}
