.recordEditPopupOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: #595959;
  z-index: 1;
  display: none;
}

.recordEditPopupOverlay.active {
  display: block;
}

.recordEditPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 90%;
  max-width: 1170px;
  max-height: 915px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background: #ffffff 0 0 no-repeat padding-box;
  padding: 40px 0 20px;
}

.recordEditPopupContainer {
  overflow: auto;
  width: 100%;
  height: 100%;
  position: relative;
}

.title {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
  padding: 0 20px;
  color: #cf2c47;
}

.subtitle {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
}

.title span {
  color: #cf2c47;
  margin-bottom: 9px;
}

.table {
  margin: 0 40px 15px;
  overflow: auto;
}

.tableWrapper {
  min-width: 720px;
}

.delete {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.popupText {
  text-align: center;
  max-width: 600px;
  padding: 0 20px;
  margin: 0 auto 26px;
  line-height: 1.5;
  font-weight: 600;
  margin-top: 70px;
}

.buttonWrapper {
  width: 100%;
  padding: 0 40px 10px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  width: 274px;
  background-color: #889398;
  border-radius: 6px;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  height: 60px;
  pointer-events: none;
  font-weight: 600;
  color: white;
}

.button.active {
  background-color: #cf2c47;
  cursor: pointer;
  pointer-events: all;
}

.textActive {
  color: #cf2c47;
}

.goBack {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 28px;
  left: 41px;
}

.goBackImage {
  width: 20px;
  height: 17px;
  margin-right: 10px;
}

.goBackImage img {
  width: 100%;
  display: block;
}

.goBackText {
  font-size: 14px;
  font-weight: 700;
}

.closeBtn {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 63px;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.closeBtn img {
  display: block;
}

.noData {
  text-align: center;
}

.contentWapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.currentRecord,
.newRecord {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.recordHeaderDescription {
  margin-top: 30px;
  margin-bottom: 25px;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.currentRecordColumn {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 3fr 2fr;
  width: 100%;
  grid-gap: 10px 10px;
  background-color: #dadada;
  padding: 20px;
  font-weight: 600;
}
.recordTableWapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-shadow: #00000029 0 0 12px;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.recordTableHeader {
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 3fr 2fr;
  width: 100%;
  font-weight: bold;
  color: #595959;
}

.headerItem,
.bodyItem {
  border-right: 2px solid #e2e2e2;
  height: 100%;
  width: 100%;
  padding: 25px 0 25px 16px;
  border-bottom: 2px solid #e2e2e2;
}

.lastHeaderItem,
.lastBodyItem {
  border-right: none;
}

.recordTableBody {
  color: #595959;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 3fr 2fr;
  width: 100%;
}

.bottonButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.nonText {
  margin-top: 140px;
}

.changeResult {
  width: 100px;
}

@media screen and (max-width: 992px) {
  .recordEditPopup {
    max-height: unset;
    padding-top: 20px;
  }

  .table {
    margin: 0 15px;
  }

  .buttonWrapper {
    padding: 0 15px 10px;
  }

  .title {
    margin-top: 40px;
    font-size: 25px;
  }

  .buttons {
    display: block;
    margin: auto;
    width: fit-content;
    max-width: 90%;
  }

  .button {
    margin-bottom: 20px;
    max-width: 100%;
  }

  .goBack {
    top: 8px;
    left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .recordEditPopup {
    width: 100%;
    height: 100%;
  }
}
