.popupOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: #595959;
  z-index: 1;
  display: none;
}

.popupOverlay.active {
  display: block;
}

.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-height: 90%;
  max-width: 1170px;
  max-height: 915px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background: #ffffff 0 0 no-repeat padding-box;
  padding: 40px 0 20px;
}

.popupContainer {
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 0 32px;
  position: relative;
}

.title {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
  padding: 0 20px;
  margin-bottom: 20px;
}

.title span {
  color: #cf2c47;
  margin-bottom: 9px;
}

.subtitle {
  margin-top: 5px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.closeBtn {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 63px;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.closeBtn img {
  display: block;
}

.goBack {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 28px;
  left: 41px;
}

.goBackImage {
  width: 20px;
  height: 17px;
  margin-right: 10px;
}

.goBackImage img {
  width: 100%;
  display: block;
}

.goBackText {
  font-size: 14px;
  font-weight: 700;
}

@media screen and (max-width: 992px) {
  .popup {
    max-height: unset;
    padding-top: 20px;
    width: 100%;
    height: 100%;
  }

  .popupContainer {
    padding: 0 15px;
  }

  .title {
    margin-top: 40px;
    font-size: 25px;
  }

  .goBack {
    top: 25px;
    left: 15px;
  }
}

.formatSelect {
  position: absolute;
  right: 34px;
  top: 10px;
  display: flex;
  align-items: center;
}

.formatText {
  margin-right: 8px;
  font-weight: bold;
}
