.subtitle {
  color: #889398;
  margin-bottom: 30px;
}

@media screen and (max-width: 640px) {
  .subtitle {
    margin-bottom: 5px;
  }
}

.pendingTableOption {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  height: max-content;
  margin-bottom: 10px;
}

.pendingBackLink {
  border-bottom: 1px solid black;
  cursor: pointer;
  font-weight: 600;
}

.buttonContainer {
  display: flex;
  gap: 20px;
}
