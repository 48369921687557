.resultEditPopupOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background: #595959;
  z-index: 1;
  display: none;
}

.resultEditPopupOverlay.active {
  display: block;
}

.resultEditPopup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 90%;
  max-width: 1170px;
  max-height: 915px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background: #ffffff 0 0 no-repeat padding-box;
  padding: 40px 0 20px;
}

.resultEditPopupContainer {
  overflow: auto;
  width: 100%;
  height: 100%;
  position: relative;
}

.title {
  font-size: 30px;
  font-weight: 700;
  text-align: center;
  margin-top: 20px;
  padding: 0 20px;
}

.subtitle {
  margin-top: 5px;
  margin-bottom: 50px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.title span {
  color: #cf2c47;
  margin-bottom: 9px;
}

.table {
  margin: 0 40px 15px;
}

/* .tableWrapper {
  min-width: 720px;
} */

.delete {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.popupText {
  text-align: center;
  max-width: 460px;
  padding: 0 20px;
  margin: 0 auto 26px;
  line-height: 1.5;
  font-weight: 600;
}

.buttonWrapper {
  width: 100%;
  padding: 0 40px 10px;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  width: 274px;
  background-color: #889398;
  border-radius: 6px;
  font-size: 21px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 9px;
  height: 60px;
  pointer-events: none;
  font-weight: 600;
  color: white;
}

.button.active {
  background-color: #cf2c47;
  cursor: pointer;
  pointer-events: all;
}

.goBack {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 28px;
  left: 41px;
}

.goBackImage {
  width: 20px;
  height: 17px;
  margin-right: 10px;
}

.goBackImage img {
  width: 100%;
  display: block;
}

.goBackText {
  font-size: 14px;
  font-weight: 700;
}

.closeBtn {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 63px;
  height: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.closeBtn img {
  display: block;
}

.noData {
  text-align: center;
}

@media screen and (max-width: 992px) {
  .resultEditPopup {
    max-height: unset;
    padding-top: 20px;
  }

  .table {
    margin: 0 15px;
  }

  .buttonWrapper {
    padding: 0 15px 10px;
  }

  .title {
    margin-top: 40px;
    font-size: 25px;
  }

  .buttons {
    display: block;
    margin: auto;
    width: fit-content;
    max-width: 90%;
  }

  .button {
    margin-bottom: 20px;
    max-width: 100%;
  }

  .goBack {
    top: 8px;
    left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .resultEditPopup {
    width: 100%;
    height: 100%;
  }
}
