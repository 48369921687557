.checkboxContainer {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 24px;
  cursor: pointer;
}

.checkbox {
  width: 17px;
  height: 17px;
  flex: none;
  margin-right: 10px;
}

.checkbox img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.filledCheckbox {
  display: none;
}

.checkboxContainerActive .filledCheckbox {
  display: block;
}

.emptyCheckbox {
  display: block;
}

.checkboxContainerActive .emptyCheckbox {
  display: none;
}

.label {
  font-size: 16px;
  color: #313131;
}
