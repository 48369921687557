.options {
    display: flex;
    margin-top: -50px;
}

.option {
    margin: 0 5px;
    padding: 8px 10px;
    background: #EAEAEA;
    border-radius: 5px 5px 0 0;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
}

.active {
    background: #C0C0C0;
}